import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Backdrop, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField';
import { alpha } from '@mui/system';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useAddUserToPoolMutation, useClearPoolUserSelectionsMutation, useGetPoolQuery, useSetUserSelectionQtyMutation, useSetUserTipMutation } from '../api/queries';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Items, PoolResult } from 'models';
import ReceiptProcessedSpeedDial from '../components/ReceiptProcessedSpeedDial';
import TipInputDialog from '../components/TipInputDialog';
import { toast } from 'react-toastify';
import ConfirmDialog from '../components/ConfirmDialog';

// TutorialStep.js
function TutorialStep({ step, onNext, onSkip }) {
    const icon = (
        <img
            src={step.icon}
            alt="Step Icon"
            style={{
                display: 'block',
                margin: step.iconFirst ? '0 auto 0' : '20px auto 0', // Add margin when icon is below text
                width: '60px',
                height: '60px',
                transform: step.rotation
            }}
        />
    );

    const text = (
        <div className="tutorial-popup"
            style={{ position: 'relative', top: '10px', backgroundColor: 'white', borderRadius: '15px', width: '60vw', paddingTop: '5px' }}>
            <div dangerouslySetInnerHTML={{ __html: step.text }} />
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
                <button onClick={onNext}>Next</button>
                <button onClick={onSkip}>Skip</button>
            </div>
            <div style={{ height: '5px' }}></div> {/* Spacer element */}
        </div>
    );

    return (
        <div style={{ position: 'absolute', top: step.top, left: step.left }}>
            {step.iconFirst ? (
                <>
                    {icon}
                    {text}
                </>
            ) : (
                <>
                    {text}
                    {icon}
                </>
            )}
        </div>
    );
}

// TutorialOverlay.js
function TutorialOverlay({ steps, currentStep, onNext, onSkip, topBoxPosition, bottomBoxPosition }) {
    if (currentStep >= steps.length) return null; // Tutorial finished

    const overlayStyles = [
        {
            shapes: [
                { top: 0, left: 0, right: 0, height: topBoxPosition, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                { top: bottomBoxPosition, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            ]
        },
        {
            shapes: [
                { top: 0, left: 0, right: 0, height: bottomBoxPosition, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            ]
        },
        {
            shapes: [
                { top: topBoxPosition, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            ]
        },
        {
            shapes: [
                { top: topBoxPosition, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            ]
        },
        // Add more styles for other steps as needed
    ];

    const currentStyle = overlayStyles[currentStep] || { shapes: [] };

    return (
        <div className="tutorial-overlay" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000 }}>
            {currentStyle.shapes.map((shape, index) => (
                <div key={index} style={{ position: 'absolute', ...shape }}></div>
            ))}
            <TutorialStep step={steps[currentStep]} onNext={onNext} onSkip={onSkip} />
        </div>
    );
}

function ReceiptProcessed() {

    const [currentStep, setCurrentStep] = React.useState(0);
    const [boxPositions, setBoxPositions] = useState({ topBoxPosition: 0, bottomBoxPosition: 0 });
    const topBoxRef = useRef<HTMLDivElement>(null);
    const bottomBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (topBoxRef.current) {
            const rect = topBoxRef.current.getBoundingClientRect();
            setBoxPositions(prevPositions => ({
                ...prevPositions,
                topBoxPosition: rect.top
            }));
        }
        if (bottomBoxRef.current) {
            const rect = bottomBoxRef.current.getBoundingClientRect();
            setBoxPositions(prevPositions => ({
                ...prevPositions,
                bottomBoxPosition: rect.top
            }));
        }
    }, []);

    const tutorialSteps = [
        {
            text: '<div style="text-align: center;"><strong>Choose Your Food & Drinks</strong><br>Simply tap the items you ordered to mark them as yours.</div>',
            icon: `${process.env.PUBLIC_URL}/tutorialArrow.png`, top: boxPositions.bottomBoxPosition, left: "20vw", rotation: 'rotate(180deg)', iconFirst: true
        },
        {
            text: '<div style="text-align: center;"><strong>In Your Cart</strong><br>Everything you pick will be listed here. Tap again to remove.</div>',
            icon: `${process.env.PUBLIC_URL}/tutorialArrow.png`, top: "35vh", left: "20vw", rotation: 'rotate(0deg)', iconFirst: false
        },
        {
            text: '<div style="text-align: center;"><strong>Looking for Something?</strong><br>Enter the item name to search through the bill.</div>',
            icon: `${process.env.PUBLIC_URL}/tutorialArrow.png`, top: "7vh", left: "20vw", rotation: 'rotate(90deg)', iconFirst: true
        },
        {
            text: '<div style="text-align: center;"><strong>Split the Bill Easily</strong><br>Invite your friends to select their own food and drinks.</div>',
            icon: `${process.env.PUBLIC_URL}/tutorialArrow.png`, top: "7vh", left: "20vw", rotation: 'rotate(270deg)', iconFirst: true
        },
        // Add more steps as needed
    ];

    const handleNext = () => setCurrentStep((prevStep) => prevStep + 1);
    const handleSkip = () => setCurrentStep(tutorialSteps.length); // Set to length to finish

    const mainHeaderColor = '#353D3F';
    const headerColor = alpha('#7F7F7F', 1);
    const rowPadding = '5px 15px'

    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [showTipDialog, setShowTipDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    // Get the pool ID from URL params
    const [searchParams] = useSearchParams()
    const poolId = searchParams.get("poolId")

    // Fetch user state from local storage
    const userId = useSelector((state: any) => state.user.userId);

    // API Queries
    const { data, isFetching, isLoading: loadingPool } = useGetPoolQuery({ id: poolId ?? '' }, {
        pollingInterval: 1000
    });
    const [setUserSelectionQty, { isLoading }] = useSetUserSelectionQtyMutation();
    const [clearPoolUserSelections] = useClearPoolUserSelectionsMutation();
    const [addUserToPool] = useAddUserToPoolMutation();

    /**
     * Handles the share button click to invite another user to join the pool
     */
    const onShareClick = async () => {
        const shareData: ShareData = {
            url: `?poolId=${poolId}`
        }
        try {
            if (navigator.share && navigator?.canShare(shareData)) {
                await navigator.share(shareData);
            } else {
                navigator.clipboard.writeText(shareData.url ?? '');
            }
        } catch (err) {
            // Notification that share failed
            console.log('Share failed: ', err)
        }
    }

    /**
     * Resets the user selections for the pool
     */
    const onResetClick = async () => {
        await clearPoolUserSelections({
            id: poolId ?? ''
        }).unwrap();
    }

    /**
     * Handles output from the ConfirmDialog
     */
    const handleConfirmDialogOutput = () => {
        navigate(`/`);
    }

    /**
     * Handles a line item click and updates the user's quantity for that line item
     * @param index line index clicked on
     * @param qty the selected quantity
     */
    const onLineItemClick = async (index: string, qty: number) => {
        setLoading(true);
        const poolResult = await setUserSelectionQty({
            userId,
            poolId: poolId ?? '',
            index: Number(index),
            qty
        }).unwrap();
        const tipResult = poolResult.ocrResult?.tip?.find(x => x.userId === userId);
        // update tip if percentage based
        if (tipResult && tipResult.percentage) {
            const tip = getTotalDueSelectedByUser(poolResult) * (tipResult.percentage / 100);
            await updateUserTip({
                poolId: poolId ?? '',
                userId,
                tip,
                percentage: tipResult.percentage
            }).unwrap();
        }
        setLoading(false);
    };

    /**
     * Calculates the difference between the quantity of an item and the total quantity picked by users.
     * @param item - The item for which to calculate the quantity difference.
     * @returns The difference between the item's quantity and the total quantity picked by users.
     */
    const getItemQtyDifference = (item: Items) => {
        var totalPicked = item.userSelections.map(x => x.qty).reduce((a, b) => { if (a !== undefined && b) { return a + b } else return 0 }, 0);
        return Number(item.qty) - totalPicked
    }

    /**
     * Gets the userItemSelection qty for a specific item for the logged in user
     * @param item the line item to get the user's qty for
     * @returns the total picked qty
     */
    const getUserItemTotalSelected = (item: Items) => {
        var totalPicked = item.userSelections
            .filter(selection => selection.userId === userId)
            .map(x => x.qty)
            .reduce((a, b) => { if (a !== undefined && b) { return a + b } else return 0 }, 0);
        return totalPicked
    }

    /**
     * Gets the price for the bill plus the tip based on the selections made by the logged in user
     * @returns the bill total and tip for the logged in user
     */
    const getTotalDueSelectedByUser = (poolResult?: PoolResult | undefined) => {
        if (!poolResult) {
            poolResult = data;
        }
        const userTotal = poolResult?.ocrResult?.lineItems
            .filter(lineItem => {
                return lineItem.userSelections
                    .filter(selection => selection.userId === userId)
            })
            .map(x => Number(x.total) / Number(x.qty) * getUserItemTotalSelected(x))
            .reduce((a, b) => a + b, 0);

        const userTip = poolResult?.ocrResult?.tip ?
            poolResult?.ocrResult?.tip.find(tipItem => {
                return tipItem.userId === userId
            })?.tip : 0;

        const totalDue = (userTotal ?? 0) + (userTip ?? 0);

        return totalDue;
    }

    /**
     * Gets the price for the bill plus the tip based on the selections made by the logged in user
     * @returns the bill total and tip for the logged in user
     */
    const getBillTotalSelectedByUser = useCallback(() => {
        const userTotal = data?.ocrResult?.lineItems
            .filter(lineItem => {
                return lineItem.userSelections
                    .filter(selection => selection.userId === userId)
            })
            .map(x => Number(x.total) / Number(x.qty) * getUserItemTotalSelected(x))
            .reduce((a, b) => a + b, 0);

        return userTotal ?? 0;
    },[data?.ocrResult?.lineItems])

    /**
     * Calculates the current tip based on the user selection
     * @returns the value of the current user tip selection
     */
    const getTipSelectedByUserForDisplay = useCallback(() => {
        if (data?.ocrResult?.tip) {
            const userTip = (data?.ocrResult?.tip.find((tip) => tip.userId === userId)?.tip ?? 0);
            return Math.round((userTip + Number.EPSILON) * 100) / 100;
        }
        return 0;
    }, [data?.ocrResult?.tip]);

    /**
     * Finds the current user's tip
     * @returns the current user's tip selection
     */
    const getTipSelectionForCurrentUser = useCallback(() => {
        if (data?.ocrResult?.tip) {
            const tipSelection = data?.ocrResult?.tip.find((tip) => tip.userId === userId);
            return tipSelection ?? null;
        }
        return null
    }, [data?.ocrResult?.tip]);


    /**
     * Get what should be displayed next to the tip value based on user selection
     * @returns a percentage string or an empty string
     */
    const getFormattedTipPercentageSelectedByUser = () => {
        const tipResult = data?.ocrResult?.tip?.find(x => x.userId === userId);
        if (tipResult?.percentage) {
            return `${tipResult.percentage}%`;
        }
        return '';
    }

    /**
     * Gets the price for the bill based on the selections made by all users
     * @returns the bill total for the table
     */
    const getBillTotalSelectedByEveryone = useCallback(() => {
        let allTotal = data?.ocrResult?.lineItems
            .filter(lineItem => {
                return lineItem.userSelections

            })
            .map(x => Number(x.total) / Number(x.qty) * getAllItemTotalSelected(x))
            .reduce((a, b) => a + b, 0);

        allTotal = (allTotal ?? 0) + (data?.totalTip ?? 0)
        return allTotal ?? 0
    }, [data?.ocrResult?.lineItems, data?.totalTip])

    /**
     * Calcuates the bill total by summing the line items
     * @returns The bill total as a number
     */
    const getBillTotal = useCallback(() => {
        return data?.ocrResult?.lineItems.reduce((total, item) => total + Number(item.total), 0) ?? 0
    }, [data?.ocrResult?.lineItems]);

    /**
     * Adds the user to the pool
     */
    const addNewPoolUser = async () => {
        await addUserToPool({
            poolId: poolId ?? '',
            userId
        })
    }

    // Add user to pool if not already in pool
    useEffect(() => {
        if (!data?.userIds.includes(userId) && userId) {
            console.log('User not in pool')
            addNewPoolUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    // Refresh loading state after a fetch has occurred after user 
    // selection
    useEffect(() => {
        if (!isFetching && !loadingPool) setLoading(false);
    }, [isFetching, loadingPool])

    /**
     * Gets the total picked by all users for a specific line item
     * @param item the line item to calculate the total picked for
     * @returns the amount picked by all users for the line
     */
    const getAllItemTotalSelected = (item: Items) => {
        var totalPicked = item.userSelections
            .map(x => x.qty)
            .reduce((a, b) => { if (a !== undefined && b) { return a + b } else return 0 }, 0);
        return totalPicked
    }

    /**
     * Calculates the price for 1 item on a line
     * @param item the line item to calculate the single item price for
     * @returns the price of a single item on the line
     */
    const getSingleItemPrice = (item: Items) => {
        return Number(item.total) / Number(item.qty);
    }

    /**
     * Formats a number to a currency string
     * @param value 
     * @returns the formatted currency string
     */
    const getCurrencyStringForLines = (value: number) => {
        return new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(value).replace(',', ' ');
    }

    /**
     * Formats a number to display for the totals
     * @param value 
     * @returns the formatted currency string
     */
    const getCurrencyStringForTotal = (value: number) => {
        return new Intl.NumberFormat('en-ZA', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2, }).format(value).replace(',', ' ');
    }

    /**
     * Handles output from the TipInputDialog
     */
    const [updateUserTip, { isLoading: updateUserTipIsLoading }] = useSetUserTipMutation();
    const handleTipDialogOutput = async (value: number, percentage: number, lastTouched: 'value' | 'percentage') => {
        try {
            const result = await updateUserTip({
                poolId: poolId ?? '',
                userId,
                tip: value,
                percentage: lastTouched === 'percentage' ? percentage : 0
            }).unwrap();

            toast.success('Added user tip');
        } catch (err) {
            toast.error('Cannot update user tip, please try again. Contact admin if the issue persists');
        }

    }

    if (userId) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading || loading || updateUserTipIsLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <TutorialOverlay steps={tutorialSteps} currentStep={currentStep} onNext={handleNext} onSkip={handleSkip} topBoxPosition={boxPositions.topBoxPosition} bottomBoxPosition={boxPositions.bottomBoxPosition} />
                <Box sx={{
                    height: "95%",
                    display: 'flex',
                    flexDirection: 'column',

                }}>
                    {/* The search input */}
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                        <TextField
                            id="standard-search"
                            label="Search"
                            type="search"
                            variant="standard"
                            size="small"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            sx={{
                                marginBottom: '5px',
                                marginLeft: '10px',
                                '& .MuiInputBase-input': {
                                    color: 'white', // Change text color
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', // Change label color if needed
                                    '&.Mui-focused': {
                                        color: 'white', // Change to desired color for focused state
                                    },
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'white', // Change underline color before focus
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white', // Change underline color after focus
                                }
                            }}
                        />
                    </Box>

                    {/* The view that shows the entire bill */}
                    <Box sx={{ height: "30%", flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <Box ref={topBoxRef} sx={{ py: 0.5 }} display="flex" justifyContent="center" style={{ background: mainHeaderColor }}>
                            <Typography fontSize="subtitle1.fontSize" color='white'>
                                All Items
                            </Typography>
                        </Box>
                        <TableContainer component={Paper} sx={{
                            flex: 1, // Make it take equal space
                            display: 'flex',
                            flexDirection: "column",
                            overflowY: 'scroll', // Enable scrolling if content overflows
                            backgroundColor: 'rgba(255, 255, 255, 0.2)'
                        }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ background: headerColor, padding: rowPadding, fontWeight: 'bold' }}>Description</TableCell>
                                        <TableCell align="right" style={{ background: headerColor, padding: rowPadding, fontWeight: 'bold' }}>Price</TableCell>
                                        <TableCell align="right" style={{ background: headerColor, padding: rowPadding, fontWeight: 'bold' }}>Q</TableCell>
                                        <TableCell align="right" style={{ background: headerColor, padding: rowPadding, fontWeight: 'bold' }}>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    sx={{
                                        flex: 1,
                                        overflowY: 'auto', // Scrollable body
                                    }}
                                >
                                    {data?.ocrResult?.lineItems.filter(item => {
                                        return (item.description ?? '').toLowerCase().includes(searchText.toLowerCase());
                                    }).map((item, index) => {
                                        const ItemQtyDifference = getItemQtyDifference(item);
                                        if (ItemQtyDifference > 0) {
                                            return (
                                                <TableRow
                                                    onClick={() => onLineItemClick(item.index, getUserItemTotalSelected(item) + 1)}
                                                    key={index}
                                                    style={{ backgroundColor: alpha('#bfbbbe', 0.95) }}>
                                                    <TableCell style={{ padding: rowPadding, }}>{item.description}</TableCell>
                                                    <TableCell style={{ padding: rowPadding }} align="right">{getCurrencyStringForLines(getSingleItemPrice(item))}</TableCell>
                                                    <TableCell style={{ padding: rowPadding }} align="right">{getItemQtyDifference(item)}</TableCell>
                                                    <TableCell style={{ padding: rowPadding }} align="right">{getCurrencyStringForLines(Number(item.total))}</TableCell>
                                                </TableRow>
                                            );
                                        }
                                        return null;
                                    })}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Box>

                    {/* The amount owed on the bill */}
                    <Box sx={{ px: 2, pb: 0.5, pt: 0.25 }} style={{ background: headerColor }}>
                        <Grid container spacing={0}>
                            <Grid size={8}>
                                <Typography fontSize="subtitle2.fontSize">
                                    Bill Total:
                                </Typography>
                            </Grid>
                            <Grid size={1}>
                                <Typography fontSize="subtitle2.fontSize" textAlign="right">
                                    R
                                </Typography>
                            </Grid>
                            <Grid size={3}>
                                <Typography fontSize="subtitle2.fontSize" textAlign="right">
                                    {getCurrencyStringForTotal(getBillTotal())}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* The totals selected by all users */}
                    <Box sx={{ px: 2, pt: 0.5, pb: 0.25 }} style={{ background: headerColor }}>
                        <Grid container spacing={0}>
                            <Grid size={8}>
                                <Typography fontSize="subtitle2.fontSize">
                                    Claimed + Tip:
                                </Typography>
                            </Grid>
                            <Grid size={1}>
                                <Typography fontSize="subtitle2.fontSize" textAlign="right">
                                    R
                                </Typography>
                            </Grid>
                            <Grid size={3}>
                                <Typography fontSize="subtitle2.fontSize" textAlign="right">
                                    {getCurrencyStringForTotal(getBillTotalSelectedByEveryone())}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* The view that shows user selections */}
                    <Box sx={{ height: "30%", flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <Box ref={bottomBoxRef} sx={{ py: 0.5 }} display="flex" justifyContent="center" style={{ background: mainHeaderColor }} >
                            <Typography fontSize="subtitle1.fontSize" color='white'>
                                Your Items
                            </Typography>
                        </Box>
                        <TableContainer component={Paper} sx={{
                            flex: 1, // Make it take equal space
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'scroll',
                            backgroundColor: 'rgba(255, 255, 255, 0.2)'
                        }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ background: headerColor, padding: rowPadding, fontWeight: 'bold' }}>Description</TableCell>
                                        <TableCell align="right" style={{ background: headerColor, padding: rowPadding, fontWeight: 'bold' }}>Price</TableCell>
                                        <TableCell align="right" style={{ background: headerColor, padding: rowPadding, fontWeight: 'bold' }}>Q</TableCell>
                                        <TableCell align="right" style={{ background: headerColor, padding: rowPadding, fontWeight: 'bold' }}>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    sx={{
                                        flex: 1,
                                        overflowY: 'auto', // Scrollable body
                                    }}
                                >
                                    {data?.ocrResult?.lineItems.map((item, index) => {
                                        const userItemTotalSelected = getUserItemTotalSelected(item);
                                        if (userItemTotalSelected > 0) {
                                            return (
                                                <TableRow
                                                    onClick={() => onLineItemClick(item.index, userItemTotalSelected - 1)}
                                                    key={index}
                                                    style={{ backgroundColor: alpha('#bfbbbe', 0.95) }}>
                                                    <TableCell style={{ padding: rowPadding }}>{item.description}</TableCell>
                                                    <TableCell style={{ padding: rowPadding }} align="right">{getCurrencyStringForLines(getSingleItemPrice(item))}</TableCell>
                                                    <TableCell style={{ padding: rowPadding }} align="right">{userItemTotalSelected}</TableCell>
                                                    <TableCell style={{ padding: rowPadding }} align="right">{getCurrencyStringForLines(getSingleItemPrice(item) * userItemTotalSelected)}</TableCell>
                                                </TableRow>
                                            );
                                        }
                                        return null;
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    {/* The user selection totals */}
                    <Box sx={{ px: 2, pt: 0.5, pb: 0.25 }} style={{ background: headerColor }}>
                        <Grid container spacing={0}>
                            <Grid size={8}>
                                <Typography fontSize="subtitle2.fontSize">
                                    {getFormattedTipPercentageSelectedByUser()}Tip:
                                </Typography>
                            </Grid>
                            <Grid size={1}>
                                <Typography fontSize="subtitle2.fontSize" textAlign="right">
                                    R
                                </Typography>
                            </Grid>
                            <Grid size={3}>
                                <Typography fontSize="subtitle2.fontSize" textAlign="right">
                                    {getCurrencyStringForTotal(getTipSelectedByUserForDisplay())}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ px: 2, pt: 0.5, pb: 0.25 }} style={{ background: headerColor }}>
                        <Grid container spacing={0}>
                            <Grid size={8}>
                                <Typography fontSize="subtitle2.fontSize">
                                    Total:
                                </Typography>
                            </Grid>
                            <Grid size={1}>
                                <Typography fontSize="subtitle2.fontSize" textAlign="right">
                                    R
                                </Typography>
                            </Grid>
                            <Grid size={3}>
                                <Typography fontSize="subtitle2.fontSize" textAlign="right">
                                    {getCurrencyStringForTotal(getTotalDueSelectedByUser())}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <ReceiptProcessedSpeedDial
                    onFinish={() => setShowConfirmDialog(true)}
                    onReset={onResetClick}
                    onShare={onShareClick}
                    onHelp={() => setCurrentStep(0)}
                    onTip={() => setShowTipDialog(true)}
                />
                <TipInputDialog
                    open={showTipDialog}
                    input={getTipSelectionForCurrentUser()}
                    billTotal={getBillTotalSelectedByUser()}
                    output={handleTipDialogOutput}
                    onClose={() => setShowTipDialog(false)}>
                </TipInputDialog>
                <ConfirmDialog
                    open={showConfirmDialog}
                    output={handleConfirmDialogOutput}
                    onClose={() => setShowConfirmDialog(false)}
                ></ConfirmDialog>
            </>
        );
    } else {
        navigate(`/?poolId=${poolId}`);
    }
}

export default ReceiptProcessed;
