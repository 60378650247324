import React, { useEffect, useState } from 'react';
import MyButton from '../components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useUploadFileMutation } from '../api/queries';
import { Backdrop, Button, CircularProgress } from '@mui/material';

function CaptureReceiptPage() {
    // Fetch user state from local storage
    const userId = useSelector((state: any) => state.user.userId);

    const navigate = useNavigate();

    // Get the pool ID from URL params
    const [searchParams] = useSearchParams();
    const isGalleryImage = searchParams.get("isGalleryImage")

    const [imageData, setImageData] = useState<string | null>(null);
    const [isCameraOpen] = useState(isGalleryImage == null);

    const [uploadFile, { isLoading }] = useUploadFileMutation();

    useEffect(() => {
        if (isGalleryImage) {
            showGalleryImage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toBase64 = file => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
    });

    const handleAnalyseReceiptClick = async () => {
        if (!imageData) return;
        const blob = await (await fetch(imageData)).blob();
        const imageToUpload = await toBase64(blob);
        const result = await uploadFile({
            imageData: imageToUpload ?? '',
            userId
        }).unwrap();

        if (!result) console.log('Error while uploading file');
        navigate(`/receipt-processed?poolId=${result.id}`);
    };

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setImageData(newUrl);
            }
        }
    };

    const showGalleryImage = () => {
        // Retrieve file data from sessionStorage
        const imageSrc = sessionStorage.getItem('fileContent');
        setImageData(imageSrc || null);
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            
            {isCameraOpen && !imageData && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={300}
                    sx={{ width: '80%', margin: 'auto', paddingTop: '40%' }}
                >
                    <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                        style={{
                            display: 'none'
                        }}
                    />
                    <label htmlFor="icon-button-file">
                        <Button
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            variant='contained'
                        >
                            Open Camera
                        </Button>
                    </label>
                    {/* <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        style={{ width: '100%' }}
                        type='file'
                        capture="environment"
                    /> */}
                </Box>
            )}

            {imageData && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={300}
                    sx={{ width: '80%', margin: 'auto', paddingTop: '40%' }}
                >
                    <img
                        src={imageData}
                        alt="Captured"
                        style={{ width: '100%' }}
                    />
                </Box>
            )}

            <Box display="flex" justifyContent="center" alignItems="center">

                {/* {!imageData && isCameraOpen &&
                    <MyButton
                        onClick={capture}
                        buttonText="Capture photo"
                        top="80%"
                        left="50%"
                        transform="translate(-50%, -50%) scale(1.25)"
                        width='200px'
                    />
                } */}

                {imageData &&
                    <MyButton
                        onClick={handleAnalyseReceiptClick}
                        buttonText="Analyse Receipt"
                        top="80%"
                        left="50%"
                        transform="translate(-50%, -50%) scale(1.25)"
                        width='200px'
                    />
                }
            </Box>
        </>
    );
}

export default CaptureReceiptPage;